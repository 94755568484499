var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-firmaConciliacion","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_c('strong',[_vm._v("Aprobación Conciliación Turno:")]),_vm._v(" "+_vm._s(_vm.turno.id)+" ")]),_c('button',{ref:"closeModalFirma",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"},on:{"click":function($event){return _vm.cerrarModal()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12"},[_c('div',{staticClass:"card card-info card-outline collapsed-card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group col-md-12"},[(_vm.conciliacion)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('p',[_vm._v(" Firmado por: "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.conciliacion.usuario_f.name))])]),_vm._m(1),_c('p',{staticClass:"text-center mr-5 ml-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.uri_docs + _vm.conciliacion.usuario_f.link_firma}})]),_c('p',[_vm._v(" Fecha: "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.conciliacion.fecha_firma_front))])]),_c('p',[_vm._v(" Observación: "),_c('br'),_vm._v(" "+_vm._s(_vm.conciliacion.observacion_front)+" ")])])]):(
                        _vm.$store.getters.can(
                          'tif.conciliaciones.firma.frontera'
                        )
                      )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"callout callout-warning"},[_c('small',[_c('strong',[_vm._v(" "+_vm._s(_vm.$store.getters.getAuthAzure ? `La sesión esta iniciada con Azure.` : `Para firmar la concilicación por favor digite su clave.`)+" ")])])])]),(!_vm.$store.getters.getAuthAzure)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"sign_password"}},[_vm._v("Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaFrontera.pass),expression:"firmaFrontera.pass"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaFrontera.pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.firmaFrontera.pass)},on:{"blur":function($event){return _vm.valGenerales()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaFrontera, "pass", $event.target.value)}}})]):_vm._e(),(!_vm.$store.getters.getAuthAzure)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"re_sign_password"}},[_vm._v("Confirme Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaFrontera.repass),expression:"firmaFrontera.repass"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaFrontera.repass.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.firmaFrontera.repass)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaFrontera, "repass", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"observaciones"}},[_vm._v("Observaciones")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaFrontera.obvs),expression:"firmaFrontera.obvs"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaFrontera.obvs.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"vtype":"date","rows":"2"},domProps:{"value":(_vm.firmaFrontera.obvs)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaFrontera, "obvs", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.firmaFrontera.$invalid),expression:"!$v.firmaFrontera.$invalid"}],staticClass:"btn btn-sm bg-success",attrs:{"type":"button","title":"Guardar firma","data-toggle":"tooltip"},on:{"click":function($event){return _vm.saveFirma('f')}}},[_c('i',{staticClass:"fas fa-signature"}),_vm._m(2)])])]):_c('div',[_vm._m(3)])])])])]),_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12"},[_c('div',{staticClass:"card card-info card-outline collapsed-card"},[_vm._m(4),(
                    _vm.$store.getters.can(
                      'tif.conciliaciones.firma.transportadora'
                    )
                  )?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group col-md-12"},[(_vm.conciliacion && !_vm.conciliacion.usuario_t)?_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"sign_password"}},[_vm._v("Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaTransportadora.pass),expression:"firmaTransportadora.pass"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaTransportadora.pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.firmaTransportadora.pass)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaTransportadora, "pass", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"re_sign_password"}},[_vm._v("Confirme Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaTransportadora.repass),expression:"firmaTransportadora.repass"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaTransportadora.repass.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.firmaTransportadora.repass)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaTransportadora, "repass", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"observaciones"}},[_vm._v("Observaciones")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.firmaTransportadora.obvs),expression:"firmaTransportadora.obvs"}],staticClass:"form-control form-control-sm",class:_vm.$v.firmaTransportadora.obvs.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"vtype":"date","rows":"2"},domProps:{"value":(_vm.firmaTransportadora.obvs)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.firmaTransportadora, "obvs", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.firmaTransportadora.$invalid),expression:"!$v.firmaTransportadora.$invalid"}],staticClass:"btn btn-sm bg-success",attrs:{"type":"button","title":"Guardar firma","data-toggle":"tooltip"},on:{"click":function($event){return _vm.saveFirma('t')}}},[_c('i',{staticClass:"fas fa-signature"}),_vm._m(6)])])]):_vm._e(),(_vm.conciliacion && _vm.conciliacion.usuario_t)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('p',[_vm._v(" Firmado por: "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.conciliacion.usuario_t.name))])]),_vm._m(7),_c('p',{staticClass:"text-center mr-5 ml-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.uri_docs + _vm.conciliacion.usuario_t.link_firma}})]),_c('p',[_vm._v(" Fecha: "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.conciliacion.fecha_firma_trans))])]),_c('p',[_vm._v(" Observación: "),_c('br'),_vm._v(" "+_vm._s(_vm.conciliacion.observacion_trans)+" ")])])]):_vm._e()])]):_c('div',{staticClass:"card-body"},[_vm._m(8)])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_vm._v("Firma Frontera")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Firma: "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_c('small',[_vm._v("Firmar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"callout callout-warning"},[_c('small',[_c('strong',[_vm._v("Frontera aun no a firmado esta conciliacion")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_vm._v("Firma Transportadora")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"callout callout-warning"},[_c('small',[_c('strong',[_vm._v(" Para firmar la concilicación por favor digite su clave. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_c('small',[_vm._v("Firmar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Firma: "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"callout callout-warning"},[_c('small',[_c('strong',[_vm._v("Usted no hace parte de la Transportadora")])])])
}]

export { render, staticRenderFns }