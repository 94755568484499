<template>
  <div>
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-firmaConciliacion"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              <strong>Aprobación Conciliación Turno:</strong> {{ turno.id }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalFirma"
              @click="cerrarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="card card-info card-outline collapsed-card">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">Firma Frontera</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        class="btn btn-tool"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group col-md-12">
                      <div class="row" v-if="conciliacion">
                        <div class="form-group col-md-12">
                          <p>
                            Firmado por: <br />
                            <strong>{{ conciliacion.usuario_f.name }}</strong>
                          </p>
                          <p>Firma: <br /></p>
                          <p class="text-center mr-5 ml-5">
                            <img
                              :src="
                                uri_docs + conciliacion.usuario_f.link_firma
                              "
                              class="img-fluid"
                            />
                          </p>

                          <p>
                            Fecha: <br />
                            <strong>{{
                              conciliacion.fecha_firma_front
                            }}</strong>
                          </p>
                          <p>
                            Observación: <br />
                            {{ conciliacion.observacion_front }}
                          </p>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          $store.getters.can(
                            'tif.conciliaciones.firma.frontera'
                          )
                        "
                        class="row"
                      >
                        <div class="form-group col-md-12">
                          <div class="callout callout-warning">
                            <small
                              ><strong>
                                {{
                                  $store.getters.getAuthAzure
                                    ? `La sesión esta iniciada con Azure.`
                                    : `Para firmar la concilicación por favor digite su clave.`
                                }}
                              </strong></small
                            >
                          </div>
                        </div>
                        <div
                          class="form-group col-md-6"
                          v-if="!$store.getters.getAuthAzure"
                        >
                          <label for="sign_password">Contraseña</label>
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            v-model="firmaFrontera.pass"
                            :class="
                              $v.firmaFrontera.pass.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @blur="valGenerales()"
                          />
                        </div>
                        <div
                          class="form-group col-md-6"
                          v-if="!$store.getters.getAuthAzure"
                        >
                          <label for="re_sign_password"
                            >Confirme Contraseña</label
                          >
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            v-model="firmaFrontera.repass"
                            :class="
                              $v.firmaFrontera.repass.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-12">
                          <label for="observaciones">Observaciones</label
                          ><textarea
                            vtype="date"
                            rows="2"
                            class="form-control form-control-sm"
                            v-model="firmaFrontera.obvs"
                            :class="
                              $v.firmaFrontera.obvs.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          ></textarea>
                        </div>
                        <div class="form-group col-md-12">
                          <button
                            type="button"
                            title="Guardar firma"
                            class="btn btn-sm bg-success"
                            data-toggle="tooltip"
                            @click="saveFirma('f')"
                            v-show="!$v.firmaFrontera.$invalid"
                          >
                            <i class="fas fa-signature"></i>
                            <div>
                              <span><small>Firmar</small></span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div v-else>
                        <div class="callout callout-warning">
                          <small
                            ><strong
                              >Frontera aun no a firmado esta
                              conciliacion</strong
                            ></small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="card card-info card-outline collapsed-card">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">Firma Transportadora</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        class="btn btn-tool"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="card-body"
                    v-if="
                      $store.getters.can(
                        'tif.conciliaciones.firma.transportadora'
                      )
                    "
                  >
                    <div class="form-group col-md-12">
                      <div
                        class="row"
                        v-if="conciliacion && !conciliacion.usuario_t"
                      >
                        <div class="form-group col-md-12">
                          <div class="callout callout-warning">
                            <small
                              ><strong>
                                Para firmar la concilicación por favor digite su
                                clave.
                              </strong></small
                            >
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="sign_password">Contraseña</label>
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            v-model="firmaTransportadora.pass"
                            :class="
                              $v.firmaTransportadora.pass.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="re_sign_password"
                            >Confirme Contraseña</label
                          >
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            v-model="firmaTransportadora.repass"
                            :class="
                              $v.firmaTransportadora.repass.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-12">
                          <label for="observaciones">Observaciones</label
                          ><textarea
                            vtype="date"
                            rows="2"
                            class="form-control form-control-sm"
                            v-model="firmaTransportadora.obvs"
                            :class="
                              $v.firmaTransportadora.obvs.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          ></textarea>
                        </div>
                        <div class="form-group col-md-12">
                          <button
                            type="button"
                            title="Guardar firma"
                            class="btn btn-sm bg-success"
                            data-toggle="tooltip"
                            @click="saveFirma('t')"
                            v-show="!$v.firmaTransportadora.$invalid"
                          >
                            <i class="fas fa-signature"></i>
                            <div>
                              <span><small>Firmar</small></span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="conciliacion && conciliacion.usuario_t"
                        class="row"
                      >
                        <div class="form-group col-md-12">
                          <p>
                            Firmado por: <br />
                            <strong>{{ conciliacion.usuario_t.name }}</strong>
                          </p>
                          <p>Firma: <br /></p>
                          <p class="text-center mr-5 ml-5">
                            <img
                              :src="
                                uri_docs + conciliacion.usuario_t.link_firma
                              "
                              class="img-fluid"
                            />
                          </p>
                          <p>
                            Fecha: <br />
                            <strong>{{
                              conciliacion.fecha_firma_trans
                            }}</strong>
                          </p>
                          <p>
                            Observación: <br />
                            {{ conciliacion.observacion_trans }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card-body">
                    <div class="callout callout-warning">
                      <small
                        ><strong
                          >Usted no hace parte de la Transportadora</strong
                        ></small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "TifFirmaConciliacion",
  components: {
    Loading,
    /* ViajesInfoPuntos, */
    //TifTurnoMapa,
  },

  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      accion: 1,
      cargando: false,
      turno_id: null,
      turno: {},
      //det_puntos: {},
      conciliacion: null,
      firmaFrontera: {
        pass: null,
        repass: null,
        obvs: null,
      },
      firmaTransportadora: {
        pass: null,
        repass: null,
        obvs: null,
      },
      det_mapa: [],
    };
  },
  validations() {
    let firm_front = {};
    if (!this.$store.getters.getAuthAzure) {
      firm_front = {
        pass: {
          required,
        },
        repass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        obvs: {
          required,
        },
      };
    } else {
      firm_front = {
        obvs: {
          required,
        },
      };
    }

    return {
      firmaFrontera: firm_front,
      firmaTransportadora: {
        pass: {
          required,
        },
        repass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        obvs: {
          required,
        },
      },
    };
  },

  methods: {
    llenar_modal_firma(item) {
      this.turno = item;
      this.getConciliacion();
    },

    getConciliacion() {
      axios
        .get("/api/tif/conciliaciones/getconciliacion", {
          params: this.turno.id,
        })
        .then((response) => {
          this.conciliacion = response.data[0];
          if (this.conciliacion === undefined) {
            this.conciliacion = null;
          }
        });
    },

    saveFirma(x) {
      this.$parent.cargando = true;
      let form = {};

      //Variable tarifa Pozos Satelites
      let tiempo_operativoP = this.turno.tiempo_op_ps * 60;

      //Variable tarifa Tiempos
      let tiempo_operativoT =
        this.turno.horas_turno * 60 -
        this.turno.tiempoDisponible -
        this.turno.tiempoIndisponible;

      //Variable tarifa fijo + variable
      let tiempo_operativoF =
        this.turno.horas_turno * 60 - this.turno.tiempoIndisponible;

      if (x == "f") {
        form = {
          tif_turno_id: this.turno.id,
          observacion_front: this.firmaFrontera.obvs,
          pass: this.firmaFrontera.pass,
        };
      } else {
        if (this.turno.tipo_tarifa == 1) {
          form = {
            id: this.conciliacion.id,
            tif_turno_id: this.turno.id,
            observacion_trans: this.firmaTransportadora.obvs,
            pass: this.firmaTransportadora.pass,
            tiempo_disponible: this.turno.tiempoDisponible,
            tiempo_indisponible: this.turno.tiempoIndisponible,
            tiempo_operativoP: tiempo_operativoP,
            tipo_tarifa: this.turno.tipo_tarifa,
            estado: 9,
          };
        } else if (this.turno.tipo_tarifa == 2) {
          form = {
            id: this.conciliacion.id,
            tif_turno_id: this.turno.id,
            observacion_trans: this.firmaTransportadora.obvs,
            pass: this.firmaTransportadora.pass,
            tiempo_indisponible: this.turno.tiempoIndisponible,
            tiempo_operativoF: tiempo_operativoF,
            km_final: this.turno.tif_km.km_final,
            tipo_tarifa: this.turno.tipo_tarifa,
            estado: 9,
          };
        } else {
          form = {
            id: this.conciliacion.id,
            tif_turno_id: this.turno.id,
            observacion_trans: this.firmaTransportadora.obvs,
            pass: this.firmaTransportadora.pass,
            tiempo_disponible: this.turno.tiempoDisponible,
            tiempo_indisponible: this.turno.tiempoIndisponible,
            tiempo_operativoT: tiempo_operativoT,
            tipo_tarifa: this.turno.tipo_tarifa,
            estado: 9,
          };
        }
      }
      form.loginAzure = this.$store.getters.getAuthAzure;

      axios({
        method: "PUT",
        url: "/api/tif/conciliaciones/firma",
        data: form,
      })
        .then((response) => {
          this.$parent.cargando = false;
          if (response.data.message) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            if (form.estado) {
              this.$parent.getIndex(this.$parent.page);
              this.$refs.closeModalFirma.click();
            } else {
              this.getConciliacion();
              this.$parent.getIndex(this.$parent.page);
            }

            this.$swal({
              icon: "success",
              title: "Conciliación firmada...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            this.$refs.closeModalFirma.click();
          }
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e.response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cerrarModal() {
      this.$parent.getIndex(this.$parent.page);
      this.$refs.closeModalFirma.click();
      this.firmaFrontera = {};
      this.firmaTransportadora = {};
      this.cargando = false;
    },

    valGenerales() {
      this.turno.tif_novedades.forEach((novedades) => {
        if (
          novedades.tipo_novedad != 4 &&
          novedades.tipo_novedad != 10 &&
          novedades.justificacion == null
        ) {
          this.firmaFrontera = {};
          this.firmaTransportadora = {};
          this.$swal({
            icon: "error",
            title: `Se encuentra novedad/es sin justificar para el turno ${this.turno.id}, por favor verificar...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      });

      this.turno.tif_puntos_turno.forEach((puntos) => {
        if (puntos.actividad == 2 && puntos.cant_producto == null) {
          this.firmaFrontera = {};
          this.firmaTransportadora = {};
          this.$swal({
            icon: "error",
            title: `La cantidad de producto descargue se encuentra sin diligenciar para el turno ${this.turno.id}, por favor verificar...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        // else if (
        //   this.turno.producto_id != 31 &&
        //   this.turno.producto_id != 32 &&
        //   puntos.actividad == 2 &&
        //   puntos.cant_producto == null
        // ) {
        //   this.firmaFrontera = {};
        //   this.firmaTransportadora = {};
        //   this.$swal({
        //     icon: "error",
        //     title: `La cantidad de producto cargue se encuentra sin diligenciar para el turno ${this.turno.id}, por favor verificar...`,
        //     toast: true,
        //     position: "top-end",
        //     showConfirmButton: false,
        //     timer: 4000,
        //     timerProgressBar: true,
        //   });
        // }
      });

      if (!this.turno.tif_km && this.turno.tipo_tarifa == 2) {
        this.firmaFrontera = {};
        this.firmaTransportadora = {};
        this.$swal({
          icon: "error",
          title: `No se han conciliado kilometros para el turno ${this.turno.id}, por favor verificar...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      if (
        this.turno.tif_puntos_turno.length == 0 &&
        this.turno.tif_recorridos.length == 1
      ) {
        this.firmaFrontera = {};
        this.firmaTransportadora = {};
        this.$swal({
          icon: "error",
          title: `No se han conciliado tiempos para el turno ${this.turno.id}, por favor verificar...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (this.turno.tif_det_tarifa_tiempo_id == null) {
        this.firmaFrontera = {};
        this.firmaTransportadora = {};
        this.$swal({
          icon: "error",
          title: `El turno no cuenta con tarifa asociada`,
          text: `Se debe recalcular el turno y si persiste el problema validar el contrato y que la tarifa asociada no se encuentre vencida`,
          showConfirmButton: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {},
};
</script>
